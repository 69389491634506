import ReactGA from "react-ga"
import Cookies from "js-cookie"
import 'clientjs'

const COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED = "gdpr-google-analytics"

// the following running modes are used with window.GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_RUNNING_WITH_MODE
const GA_MODE_RUNNING_COOKIES_DISABLED = "runningCookiesDisabled"
const GA_MODE_RUNNING_COOKIES_ENABLED = "runningCookieEnabled"

function determineClinetId() {
    const client = new ClientJS(); // eslint-disable-line no-undef
    const fingerprint = client.getFingerprint();
    return fingerprint;
}

function initializeGA(pluginOpts) {
    var gaOptions = {};
    if (!isCookiesEnabled()) {
        const clientFingerprint = determineClinetId();
        gaOptions = {
            storage: 'none', // Disable cookies for google analytics
            clientId: clientFingerprint // Set custom client fingerprint
        }
        window.GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_RUNNING_WITH_MODE = GA_MODE_RUNNING_COOKIES_DISABLED;
        return;
    } else {
        window.GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_RUNNING_WITH_MODE = GA_MODE_RUNNING_COOKIES_ENABLED;
    }

    let reactGaOptions = {gaOptions};
    if (pluginOpts.reactGaOptions !== undefined) {
        gaOptions = {...pluginOpts.reactGaOptions.gaOptions, ...gaOptions};
        reactGaOptions = {...pluginOpts.reactGaOptions, ...{'gaOptions': gaOptions}};
    }

    ReactGA.initialize(pluginOpts.trackingId, reactGaOptions)
    ReactGA.set({ anonymizeIp: isAnonymizeIpEnabled(pluginOpts) })
}

function isCookiesEnabled() {
    return Cookies.get(COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED) === "true" || 
    Cookies.get(COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED) === "1"
}

function isAnonymizeIpEnabled(pluginOptions) {
    return (pluginOptions.anonymizeIP !== undefined) ? pluginOptions.anonymizeIP : true
}

function isDevelopmentEnabled(pluginOptions) {
    return (pluginOptions.enableDevelopment !== undefined) ? pluginOptions.enableDevelopment : false
}

export const onClientEntry = (_, pluginOptions = {}) => {
    if (!pluginOptions.trackingId) {
        console.log("The Google Analytics GDPR plugin requires a tracking ID.");
        return null;
    }
    if (process.env.NODE_ENV !== 'production' && !isDevelopmentEnabled(pluginOptions)) {
        return null;
    }
    // start with setting from cookie if available
    // if no cookie settings is available, start with gatsby plugin settings 'autoStartWithCookiesEnabled'
    // if no plugin setting ist available, start with default setting (autoStartWithCookiesEnabled = false)
    if (Cookies.get(COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED) === undefined) {
        var autoStartWithCookiesEnabled = pluginOptions.autoStartWithCookiesEnabled;
        autoStartWithCookiesEnabled = (autoStartWithCookiesEnabled) ? '1' : '0';
        Cookies.set(COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED, "" + autoStartWithCookiesEnabled);

        if (!pluginOptions.autoStartWithCookiesEnabled) {
            listenCookieChange('gdpr-google-analytics', () => {
                const cookie = readCookie(COOKIE_GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_COOKIES_ENABLED);
                if (cookie == 1) {
                    pluginOptions.autoStartWithCookiesEnabled = true;
                    initializeGA(pluginOptions);
                    ReactGA.set({ page: window.location.pathname, anonymizeIp: isAnonymizeIpEnabled(pluginOptions) });
                    ReactGA.pageview(window.location.pathname);
                }
            })
        }
    }

    initializeGA(pluginOptions); 
}

function isGARunningInCorrectMode() {
    const runningWithCookiesEnabled = window.GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_RUNNING_WITH_MODE === GA_MODE_RUNNING_COOKIES_ENABLED;
    return (isCookiesEnabled() === runningWithCookiesEnabled) 
}

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
    if (!pluginOptions.trackingId) {
        console.log("The Google Analytics GDPR plugin requires a tracking ID.")
        return null
    }
    if (process.env.NODE_ENV !== 'production' && !isDevelopmentEnabled(pluginOptions)) {
        return null
    }

    if(!isCookiesEnabled()) return;

    // restart google analytics with correct settings if needed
    if (!isGARunningInCorrectMode()) {
        ReactGA.ga("remove");
        initializeGA(pluginOptions)
    }

    ReactGA.set({ page: location.pathname, anonymizeIp: isAnonymizeIpEnabled(pluginOptions) });
    ReactGA.pageview(location.pathname);
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

var cookieRegistry = [];
function listenCookieChange(cookieName, callback) {
    cookieRegistry[cookieName] = readCookie(cookieName);
    setInterval(function() {
        if (cookieRegistry[cookieName]) {
            if (readCookie(cookieName) != cookieRegistry[cookieName]) {
                // update registry so we dont get triggered again
                cookieRegistry[cookieName] = readCookie(cookieName);
                return callback();
            }
        } else {
            cookieRegistry[cookieName] = readCookie(cookieName);
        }
    }, 5000);
}
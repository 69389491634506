module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/fav-128.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4ecf419ca420ddbbdd35053cdb1feb0"},
    },{
      plugin: require('../plugins/gdpr-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156273657-1","enableDevelopment":true,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":false,"gaOptions":{"sampleRate":100}}},
    },{
      plugin: require('../plugins/gdpr-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"170755913968198","enableDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

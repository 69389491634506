import ReactGA from "react-ga"
import Cookies from "js-cookie"
import 'clientjs'

const COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED = "gdpr-facebook-pixel"

// the following running modes are used with window.GATSBY_PLUGIN_GOOGLE_ANALYTICS_GDPR_RUNNING_WITH_MODE
const FP_MODE_RUNNING_COOKIES_DISABLED = "runningCookiesDisabled"
const FP_MODE_RUNNING_COOKIES_ENABLED = "runningCookieEnabled"

function determineClinetId() {
    const client = new ClientJS(); // eslint-disable-line no-undef
    const fingerprint = client.getFingerprint();
    return fingerprint;
}

function isCookiesEnabled() {
    return Cookies.get(COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED) === "true" || 
    Cookies.get(COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED) === "1"
}

function initializeFP(pluginsOpts) {
    if(!isCookiesEnabled()) {
        window.GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_RUNNING_WITH_MODE = FP_MODE_RUNNING_COOKIES_DISABLED;
    } else {
        window.GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_RUNNING_WITH_MODE = FP_MODE_RUNNING_COOKIES_ENABLED;
        window.fbq(`init`, pluginsOpts.pixelId);
    }
}

export const onClientEntry = (_, pluginOptions = {}) => {
    if (!pluginOptions.pixelId) {
        console.log("The Facebook Pixel GDPR plugin requires a tracking ID.");
        return null;
    }
    if (process.env.NODE_ENV !== 'production' && !isDevelopmentEnabled(pluginOptions)) {
        return null;
    }

    if (Cookies.get(COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED) === undefined) {
        var autoStartWithCookiesEnabled = pluginOptions.autoStartWithCookiesEnabled;
        autoStartWithCookiesEnabled = (autoStartWithCookiesEnabled) ? '1' : '0';
        Cookies.set(COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED, "" + autoStartWithCookiesEnabled);

        if (!pluginOptions.autoStartWithCookiesEnabled) {
            listenCookieChange('gdpr-facebook-pixel', () => {
                const cookie = readCookie(COOKIE_GATSBY_PLUGIN_FACEBOOK_PIXEL_GDPR_COOKIES_ENABLED);
                if (cookie == 1) {
                    initializeFP(pluginOptions)
                    window.fbq(`track`, `PageView`);
                }
            })
        }
    }

    initializeFP(pluginOptions); 
}

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
    if (!pluginOptions.pixelId) {
        console.log("The Facebook Pixel GDPR plugin requires a tracking ID.");
        return null;
    }
    if (process.env.NODE_ENV !== 'production' && !isDevelopmentEnabled(pluginOptions)) {
        return null;
    }

    if(!isCookiesEnabled()) return;

    window.fbq(`track`, `PageView`);
}

function isDevelopmentEnabled(pluginOptions) {
    return (pluginOptions.enableDevelopment !== undefined) ? pluginOptions.enableDevelopment : false
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

var cookieRegistry = [];
function listenCookieChange(cookieName, callback) {
    cookieRegistry[cookieName] = readCookie(cookieName);
    setInterval(function() {
        if (cookieRegistry[cookieName]) {
            if (readCookie(cookieName) != cookieRegistry[cookieName]) {
                // update registry so we dont get triggered again
                cookieRegistry[cookieName] = readCookie(cookieName);
                return callback();
            }
        } else {
            cookieRegistry[cookieName] = readCookie(cookieName);
        }
    }, 5000);
}